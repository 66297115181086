.header{

    display: flex;
    justify-content: space-between;
}

.logo {
    position: relative;
    margin-left: 12rem;
    width: 7rem;
    height: 8rem;
}


.header-menu {

    list-style: none;
    display: flex;
    color: white;
    gap: 3rem;
    margin-top: 2rem;
    
}


.header-menu>li:hover{

    cursor: pointer;
    color: var(--orange);
    cursor: pointer;
}
@media screen and (max-width: 768px) { 

    .header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;
    }

    .header-menu{

        flex-direction: column;
        background: var(--appColor);
        padding: 2rem;
    }

    
}