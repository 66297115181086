.footer-container{

    
    position: relative;
}

.footer-container>hr{

    border: 4px solid var(--orange);
}

.footer{

    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    height: 30rem;

}

.social-links{

    display: flex;
    gap: 6rem;
}

.social-links>img{

    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.logo-f>img{

    width: 20rem;
    
}

.blur-f-1{
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: rgb(255, 115, 0);
}
.blur-f-2{
    bottom: 0;
    left: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: rgb(255, 115, 0);
}