.join{

    
    display: flex;
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 2rem;
    gap: 12rem;
    
}

.left-j{
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}

.left-j>hr{

    position: absolute;
    width: 10.5rem;
    border: 2px solid  var(--orange);
    border-radius: 20%;
    margin: -8px;
    background: var(--orange);
}

.left-j>div{
    display: flex;
    gap: 1rem;
}

.right-j{

    display: flex;
    justify-content: center;

}

.email-container{
    display: flex;
    gap: 3rem;
    background-color: lightgray;
    padding: 1rem 2rem;
}


.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgrayz);
}

::placeholder{
    color: white;
}

.btn-j{

    background: var(--orange);
    color: white;
}

@media screen and (max-width: 768px) {

    .join{
        flex-direction: column;
        gap: 1rem;
    }

    .left-j{
        font-size: x-large;
        flex-direction: column;
    }

    .right-j{
        padding: 2rem;
    
    }


}